define('huyang/router', ['exports', 'huyang/config/environment', 'huyang-common/mixins/tracked'], function (exports, _environment, _tracked) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend(_tracked.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('login');
    this.route('login-token', { path: '/token/:token' }, function () {
      this.route('webview');
    });
    this.route('login-vendor', { path: '/vendor-token/:serviceRequest/:destination' }, function () {
      this.route('webview');
    });
    this.route('login-failed');
    this.route('logout');
    this.route('protected');

    this.route('dashboard');

    this.route('settings', function () {
      this.route('customization', function () {
        this.route('request-types', function () {
          this.route('new');
          this.route('edit', { path: 'edit/:req_type_id' });
        });
        this.route('request-labels', function () {
          this.route('new');
          this.route('edit', { path: 'edit/:req_label_id' });
        });
        this.route('asset-categories', function () {
          this.route('edit', { path: 'edit/:asset_category_id' });
        });
        this.route('trades');
        this.route('cost-categories');
      });

      this.route('buildings', { path: '/locations' }, function () {
        this.route('add');

        this.route('building', { path: '/:building_id' }, function () {
          this.route('subspaces');
          this.route('request-types');
          this.route('info');
        });
        this.route('archive');
      });

      this.route('organization', function () {
        this.route('info');
        this.route('occupants');
      });
      this.route('user', function () {
        this.route('info');
        this.route('notifications');
        this.route('timers');
        this.route('mobile-password');
      });
      this.route('export');
    });

    this.route('admin', function () {
      this.route('token-lookup');

      this.route('account', function () {
        this.route('info');
        this.route('upload-pm');
      });

      this.route('create-account');

      this.route('tracking');
    });

    this.route('requests', function () {
      this.route('mine');
      this.route('open');
      this.route('unassigned');
      this.route('closed');
      this.route('pending');
      this.route('repeating', function () {
        this.route('new');
        this.route('active');
        this.route('paused');
      });
      this.route('all');
      this.route('building', { path: '/location/:building_id' });
      this.route('add');
      this.route('new');
      this.route('error');
      this.route('search');
      this.route('from-template', { path: 'from/:template_id' });
      this.route('new-occupants', { path: '/new-occupants/:request_id' });

      this.route('request', { path: '/:request_id' }, function () {
        this.route('messages', function () {
          this.route('channel', { path: '/:channel_id' }, function () {
            this.route('send');
          });
        });
        this.route('activity', function () {
          this.route('send');
        });
        this.route('files', function () {
          this.route('send');
        });
        this.route('info', function () {
          this.route('edit');
          this.route('send');
        });
        this.route('send');

        this.route('timer', { path: '/timer/:timer_id' }, function () {
          this.route('delete');
          this.route('log');
        });
      });
      // Ember doesn't like the word view so call them filters internally
      this.route('filters', { path: '/views' }, function () {
        this.route('filter', { path: '/:filter_id' });
      });

      this.route('preventative-maintenance', function () {
        this.route('new');
        this.route('program', { path: '/:program_id' }, function () {
          this.route('info', function () {
            this.route('edit');
          });
        });
      });
    });

    this.route('print', function () {
      this.route('request', { path: ':request_id' });
    });

    this.route('profiles', function () {
      this.route('user', { path: '/:user_id' });
    });
    this.route('error');
    this.route('help');
    this.route('not-found', { path: '/*path' });
    this.route('reports', function () {
      this.route('report', { path: '/:report_id' });
      this.route('trend');
      this.route('crosstab');

      this.route('saved', function () {
        this.route('trend', { path: '/trend/:report_id' });
        this.route('crosstab', { path: '/crosstab/:report_id' });
      });
    });
    this.route('people', function () {
      this.route('vendors', function () {
        this.route('vendor', { path: '/:vendor_id' }, function () {
          this.route('cois', function () {
            this.route('new');
            this.route('coi', { path: ':coi_id' }, function () {
              this.route('edit');
            });
          });
          this.route('technicians', function () {
            this.route('new');
            this.route('technician', { path: '/:technician_id' }, function () {
              this.route('edit');
            });
          });
          this.route('history');
        });
      });
      this.route('staff', function () {
        this.route('add');
        this.route('timers');
        this.route('member', { path: 'member/:user_id' });
      });
      this.route('occupants', function () {
        this.route('manage', { path: '/' }, function () {
          this.route('users', function () {
            this.route('active');
            this.route('pending');
            this.route('inactive');
            this.route('search');
            this.route('blocked');
          });
          this.route('groups', function () {
            this.route('archive');
            this.route('group');
            this.route('search');
          });
        });
        this.route('new');
        this.route('edit', { path: '/edit/:user_id' });
        this.route('approve', { path: '/approve/:user_id' });
        this.route('occupant');
        this.route('group');
        this.route('new-group', { path: '/groups/new' });
        this.route('edit-group', { path: '/groups/edit/:group_id' });
      });
    });

    this.route('vendor', function () {
      this.route('settings', function () {
        this.route('customization');
      });
      this.route('request', { path: '/request/:request_id' });
      this.route('response', function () {
        this.route('yes', { path: '/yes/:service_request_id' });
        this.route('no', { path: '/no/:service_request_id' });
      });
    });

    this.route('vendor-login', function () {});

    this.route('timer', { path: '/timer/:timer_id' }, function () {
      this.route('pause');
      this.route('start');
    });

    this.route('assets', function () {
      this.route('new');
      this.route('category', { path: '/category/:category_id' });
      this.route('building', { path: '/location/:building_id' });
      this.route('asset', { path: '/:asset_id' }, function () {
        this.route('edit');
      });
      this.route('asset-filter-base');
    });
    this.route('asset-filter-base');

    this.route('inactive');
  });

  exports.default = Router;
});